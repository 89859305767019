@import '../../../../src/styles/variables';

.signUp {
  &--fullSizeButton {
    margin-top: 16px;

    .adoptechButtonContainer,
    .btn {
      width: 100%;
    }

    &.signUp {
      button {
        font-size: 20px;
      }
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    width: 480px;

    @include media-breakpoint-down(xxs) {
      padding: 10px;
      width: initial;
    }
  }

  .inputField {
    border-radius: 4px;
  }
  &--emailField {
    margin-bottom: 16px;

    &-red {
      .inputField {
        background: $adoptech-red-background;

        &--inputArea {
          background: $adoptech-red-05;
          color: $adoptech-red;
        }

        ::placeholder {
          color: $adoptech-red;
        }
      }
    }
  }

  &--footer {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: space-between;
  }

  &--infoMessage {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    text-align: center;

    &-error {
      color: $adoptech-red;
    }

    &-hidden {
      opacity: 0;
    }
  }

  &--login {
    @include theme('color', 'color04');
    cursor: pointer;
    font-size: 16px;
    margin-left: 5px;
    text-decoration: underline;

    &:focus {
      color: $adoptech-dark-grey-04;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }

  &--header {
    font-weight: 500;
    font-size: 30px;
    color: $adoptech-dark-grey-04;
    padding-bottom: 14px;

    @include media-breakpoint-down(xxs) {
      font-size: 24px;
    }
  }

  &--terms {
    margin-bottom: 16px;

    .adoptechCheckbox {
      input[type=checkbox]:not(:checked) + label::before,
      input[type=checkbox]:not(:checked) + label::after {
        background: $adoptech-primary-white;
        border: 1px solid $adoptech-light-grey-01;
        border-radius: 4px;
      }
      input[type=checkbox]:checked + label::before {
        border: 1px solid $adoptech-dark-grey-05;
        border-radius: 4px;
      }
      input[type=checkbox]:checked + label::after {
        border-width: 0 1.2px 1.2px 0;
      }

      input[type=checkbox]:checked + label::before,
      input[type=checkbox]:checked + label::after {
        background: $adoptech-dark-grey-04-02;
      }

      label {
        font-size: 14px;
        color: $adoptech-dark-grey-05;
        padding: 2px 28px;

        a {
          color: $adoptech-primary-orange;
        }
      }
    }

    &-red {
      .adoptechCheckbox {
        input[type=checkbox]:not(:checked) + label::before,
        input[type=checkbox]:not(:checked) + label::after {
          border: 1px solid $adoptech-red;
        }
      }
    }
  }

  &--passwordOrApiError {
    margin-bottom: 16px;
  }
  &--termsError, &--passwordOrApiError {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 4px 8px;
    gap: 16px;
    height: 30px;
    background: rgba(234, 85, 66, 0.1);
    border-radius: 8px;
    font-size: 14px;
    color: #EA5542;
  }

  &--panel {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(xxs) {
      .panel {
        width: 95%;
      }
    }
  }

  &--passwordField {
    &:first-of-type {
      margin-bottom: 16px;
    }

    &-red {
      .inputField {
        background: $adoptech-red-background;

        &--inputArea, &--showPasswordButton {
          background: $adoptech-red-05;
          color: $adoptech-red;
        }
        ::placeholder {
          color: $adoptech-red;
        }
      }
    }
  }

  &--privacyStatement {
    color: $adoptech-dark-grey-07;
    font-size: 14px;

    a {
      color: $adoptech-gold;
    }
  }
}

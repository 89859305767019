@import '../../../../src/styles/variables';

.rightHandPanel {
  margin-top: 53px;

  &--title {
    font-weight: 300;
    font-size: 20px;

    color: $adoptech-dark-grey-04;
  }
  &--description {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--descriptionRow {
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:first-of-type) {
      margin-top: 12px;
    }
    svg {
      color: $adoptech-dark-green;
    }
  }
}
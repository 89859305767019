// Primary Colours
$adoptech-logo-orange: #ff621e;

$adoptech-primary-black: #000;
$adoptech-primary-dark-gray: #444445 !default;
$adoptech-primary-orange: #f1881f;
$adoptech-secondary-orange: #f99c49;
$adoptech-tertiary-orange: #f6ac63;
$adoptech-primary-orange-high-contrast: #ad5c0b;
$adoptech-primary-white: #fff;
// Secondary Colours
$adoptech-dark-grey-01: #111115;
$adoptech-dark-grey-02: #222225;
$adoptech-dark-grey-03: #333335;
$adoptech-dark-grey-04: #444445;
$adoptech-dark-grey-04-02: #555555;
$adoptech-dark-grey-05: #666665;
$adoptech-dark-grey-06: #777775;
$adoptech-dark-grey-07: #999995;
$adoptech-dark-grey-08: #aaa;
$adoptech-dark-grey-09: #b9b9b9;
$adoptech-dark-grey-10: #888885;
$adoptech-dark-grey-11: #A4A4A4;

$adoptech-light-grey-01: #ccc;
$adoptech-light-grey-02: #d2d2d2;
$adoptech-light-grey-03: #d8d8d8;
$adoptech-light-grey-04: #ddd;
$adoptech-light-grey-05: #e2e2e2;
$adoptech-light-grey-06: #e8e8e8;
$adoptech-light-grey-06-02: #eee;
$adoptech-light-grey-07: #f2f2f2;
$adoptech-light-grey-07-half-opacity: #f2f2f280;
$adoptech-light-grey-08: #f9f9f9;
$adoptech-light-grey-09: #f6f6f6;
$adoptech-light-grey-10: #F5F5F5;

$adoptech-green-02: #e2f1d9;
$adoptech-green-03: #75bc46;
$adoptech-green-04: #dceed1;
$adoptech-green-05: #e3f2da;
$adoptech-green-06: rgba(108, 170, 69, 0.2);
$adoptech-red-02: #fadfdc;
$adoptech-red-03: #f9dedb;
$adoptech-red-04: #EA55424D;
$adoptech-red-background: #fcefed;
$adoptech-red-05: #EA55421A;
$adoptech-red-06: #EA5542;
$adoptech-red-07: #FADAD7;

$adoptech-cool-green: #009883;
$adoptech-dark-green: #6caa45;
$adoptech-green: #a8d63f;
$adoptech-yellow: #ebce2a;
$adoptech-gold: #e3b132;
$adoptech-orange: #f1881f;
$adoptech-red: #e55f4e;
$adoptech-magenta: #e25eb9;
$adoptech-purple: #7f58d6;
$adoptech-dark-blue: #02569f;
$adoptech-mid-blue: #4496eb;
$adoptech-light-blue: #00baff;
$adoptech-light-blue-02: #06abc1cc;
$adoptech-light-blue-03: #02569F4D;
$adoptech-aqua-blue: #06abc1;

$adoptech-box-shadow-light: rgba(0, 0, 0, 0.2);
$adoptech-box-shadow-color: rgba(0, 0, 0, 0.25);
$adoptech-box-shadow-hover: rgba(0, 0, 0, 0.3);
$adoptech-box-shadow: 0px 0px 5px $adoptech-light-grey-05;
$adoptech-box-shadow-dark: 1px 1px 5px $adoptech-dark-grey-07;

$adoptech-orange-01: #efa82c;
$adoptech-orange-02: #f7d395;
$adoptech-orange-03: #fae5bf;
$adoptech-orange-04: #fbe9ca;
$adoptech-orange-05: #FADFDD;
$adoptech-orange-06: #E36053;
$adoptech-orange-07: #FFC700;
$adoptech-orange-08: #FF621E;
$adoptech-orange-09: #EBAC6D;
$adoptech-green-cyan: #51C390;
$adoptech-searchbar-width: 430px;
$adoptech-header-grid-template-columns: 1fr 4fr 1fr;
$adoptech-max-z-index: 999999;

// Bootstrap overrides
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'themes';

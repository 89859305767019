@import '../../../styles/variables';

.startLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &--content {
    align-self: center;
    display: flex;
    flex: 1;
    max-width: 960px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      max-width: 480px;
    }
  }

  &--left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &--leftContainer {
    @include media-breakpoint-down(lg) {
      margin: 0 10px;
    }
  }

  &--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    .startLayout--rightContainer {
      display: flex;

      padding-left: 130px;
    }
  }
}

@import '../../../../src/styles/variables';

.dividerWithText {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $adoptech-dark-grey-05;
  padding: 8px 0;

  hr {
    background-color: $adoptech-light-grey-01;
    flex: 1;
  }

  &--text {
    font-size: 14px;
    padding: 0 16px;
  }
}

@import '~bootstrap/scss/bootstrap.scss';
@import '../../../../src/styles/variables';

html {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

#root {
  height: 100%;
}

.unauthenticated {
  height: 100%;

  &--body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      box-shadow: 0 0 0 50px #f2f2f2 inset;
    }
  }
}

.grecaptcha-badge {
  /* stylelint-disable declaration-no-important */
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;

  &:hover {
    width: 256px !important;
  }
  /* stylelint-enable declaration-no-important */
}

@import '../../styles/variables';

.verificationSent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 40px;

  &--resend {
    color: $adoptech-gold;
    cursor: pointer;
  }

  &--resendPrompt {
    display: flex;
    gap: 5px;
  }

  &--text {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
  }

  &--title {
    color: $adoptech-dark-grey-04;
    font-size: 30px;
  }

  &--emailAddress {
    font-weight: bold;
    font-size: 14px;
    color: $adoptech-dark-grey-03;
  }
}

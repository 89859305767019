// TODO: remove this file, looks now we dont use themes for simplicity
$themes: (
  adoptech: (
    color01: #444445,
    color02: #666665,
    color03: #666665,
    color04: #EFA82C,
    color05: #444445,
    color06: #666665,
    color07: #666665,
    color08: #999995,
    color09: #333335,
  ),
  newStream: (
    color01: #152d3e,
    color02: #3a7e6c,
    color03: #69a469,
    color04: #f1881f,
    color05: #2b5a7b,
    color06: #fff,
    color07: #809cb0,
    color08: #fff,
    color09: #111,
  ),
);

@mixin theme($key, $value) {
  @each $theme-key, $theme-value in $themes {
    .body-#{$theme-key} & {
      #{$key}: map-get(map-get($themes, $theme-key), $value);
    }
  }
}

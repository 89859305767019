@import '../../../../src/styles/variables';

.login {
  &--signUpRow {
    display: flex;
    color: $adoptech-dark-grey-05;
  }

  &--signUp {
    @include theme('color', 'color04');
    cursor: pointer;
    font-size: 16px;
    margin-left: 5px;
    text-decoration: underline;

    &:focus {
      color: $adoptech-dark-grey-04;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }

  &--fullSizeButton {
    margin-top: 14px;
    margin-bottom: 5px;

    .adoptechButtonContainer,
    .btn {
      width: 100%;
    }

    &.login {
      button {
        font-size: 20px;
      }
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    width: 480px;
    min-height: 500px;

    @include media-breakpoint-down(xxs) {
      padding: 10px;
      width: initial;
    }
  }

  &--emailField {
    margin-bottom: 10px;

    &-flex {
      flex: 1;
    }

    &-red {
      .inputField {
        background-color: $adoptech-red-background;

        &--inputArea {
          background-color: $adoptech-red-background;
          color: $adoptech-red;
        }
      }
    }
  }

  &--footer {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 5px;
  }

  &--resendPassword {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;

    &:focus {
      color: $adoptech-dark-grey-04;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }

  &--forgottenPassword {
    @include theme('color', 'color04');
    cursor: pointer;
    font-size: 16px;
    margin-top: 15px;
    text-decoration: underline;

    &:focus {
      color: $adoptech-dark-grey-04;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }

  &--header {
    color: $adoptech-dark-grey-04;
    font-size: 30px;
    font-weight: 300;
    padding-bottom: 15px;
    text-align: center;

    @include media-breakpoint-down(xxs) {
      font-size: 24px;
    }
  }

  &--panel {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(xxs) {
      .panel {
        width: 95%;
      }
    }
  }

  &--passwordField {
    margin-bottom: 15px;
    flex: 1;

    &-hidden {
      display: none;
    }

    &-red {
      .inputField {
        background-color: $adoptech-red-background;

        &--inputArea {
          background-color: $adoptech-red-background;
          color: $adoptech-red;
        }

        &--showPasswordButton {
          color: $adoptech-red;
        }
      }
    }
  }

  &--resetPasswordMessage {
    color: $adoptech-dark-grey-05;

    &-hidden {
      display: none;
    }
  }

  &--createAccount {
    color: $adoptech-dark-grey-07;
    cursor: pointer;
    font-size: 14px;
    padding: 2px;
    text-decoration: underline;

    &:focus {
      color: $adoptech-dark-grey-04;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }

  &--subheader {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    padding-bottom: 10px;

    &-red {
      color: $adoptech-red;
    }
  }
}

@import '../../../styles/variables';

.startHeader {
  /* Header */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  height: 75.97px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  @include media-breakpoint-down(lg) {
    gap: 20px;
  }

  &--logo {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;

    img {
      height: 28px;
    }
  }

  &--logoAndLink {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 20px;
    }
  }

  &--progressBar {
    background-color: $adoptech-orange-05;
    height: 8px;
    width: 100%;

    .progressBar--progress {
      background-color: $adoptech-primary-orange;
    }
  }

  &--signIn {
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
    padding-right: 40px;
    position: absolute;
    width: 100%;

    @include media-breakpoint-down(lg) {
      justify-content: center;
      padding-right: 0;
      top: 30px;
    }
  }

  &--signInLink {
    a {
      text-decoration: underline;
      color: $adoptech-primary-orange;
    }
  }
}

@import '../../../../src/styles/variables';

.twoFactorAuth {
  flex-grow: 1;

  &--panel {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &--content {
    display: flex;
    flex-direction: column;
    height: 385px;
    margin: 30px 50px;
    width: 500px;
  }

  &--header {
    color: $adoptech-dark-grey-04;
    font-size: 30px;
    font-weight: 300;
    padding-bottom: 15px;
    text-align: center;
  }

  &--subheader {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    height: 35px;
    padding-bottom: 5px;

    &-red {
      color: $adoptech-red;
    }
  }

  &--tokenLabel {
    color: $adoptech-dark-grey-07;
    font-size: 14px;
    margin-top: 30px;
  }

  &--tokenField {
    margin-bottom: 20px;
  }

  &--infoMessage {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    text-align: center;

    &--error {
      color: $adoptech-red;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &--smsMessage {
    color: $adoptech-dark-grey-05;
    font-size: 14px;
    text-align: center;

    &--error {
      color: $adoptech-red;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &--actions {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }

  &--smsRequest {
    color: $adoptech-dark-grey-07;
    cursor: pointer;
    font-size: 14px;
    padding: 2px;
    text-decoration: underline;

    &:focus {
      color: $adoptech-dark-grey-04;
      outline: 1px dashed $adoptech-dark-grey-07;
    }
  }
}
